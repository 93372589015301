<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
    >
      <v-card>
        <v-card-title>
          บทความ <v-spacer></v-spacer> <v-btn
            color="primary"
            @click="newcontent"
          >
            เพิ่มบทความ
          </v-btn>
        </v-card-title>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col>
            <v-text-field
              v-model="search"
              label="ค้นหา บทความ"
              dense
              :prepend-icon="icons.mdiMagnify"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col>
            <v-data-table
              :search="search"
              :headers="headers"
              :items="contents"
              :items-per-page="25"
              class="elevation-1"
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-chip :color="item.status === 'active' ? 'info': item.status === 'finish' ? 'success': item.status === 'draft' ? 'warning': 'error'">
                  {{ getstatustext(item.status) }}
                </v-chip>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <div :style="{ display: 'flex' }">
                  <v-btn
                    icon
                    color="warning"
                    @click="viewcontent(item)"
                  >
                    <v-icon>{{ icons.mdiMagnify }}</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="info"
                    @click="editcontent(item)"
                  >
                    <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="error"
                    @click="deleteeditcontent(item)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        :retain-focus="false"
        persistent
        max-width="100%"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">บทความ</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="currentcontent">
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="currentcontent.title"
                    label="Title* (ไม่ควรเกิน 60 ตัวอักษร)"
                    counter
                    dense
                    outlined
                    :rules="[v=> !!v || 'Required']"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="currentcontent.description"
                    label="คำอธิบาย* (ไม่ควรเกิน 120 ตัวอักษร)"
                    counter
                    dense
                    outlined
                    :rules="[v=> !!v || 'Required']"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="currentcontent.ref"
                    :prefix="`${mainpage.domain}/content/${contentgroupidtotext(currentcontent.contentgroup_id)}/`"
                    label="ลิ้งค์"
                    dense
                    hide-details
                    outlined
                    :rules="[v=> !!v || 'Required']"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-autocomplete
                    v-model="currentcontent.contentgroup_id"
                    :items="contentgroups"
                    :search-input.sync="findcontentgroupname"
                    hide-no-data
                    hide-selected
                    dense
                    outlined
                    item-text="name"
                    item-value="_id"
                    label="หมวดหมู่"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                >
                  <v-select
                    v-model="currentcontent.status"
                    label="สถานะ"
                    dense
                    hide-details
                    outlined
                    :rules="[v=> !!v || 'Required']"
                    item-text="label"
                    item-value="value"
                    :items="statusitems"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-combobox
                    v-model="currentcontent.keywords"
                    multiple
                    chips
                    deletable-chips
                    label="Keywords"
                    hide-details
                    outlined
                  ></v-combobox>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-card>
                    <v-toolbar
                      flat
                      color="primary"
                      dark
                    >
                      <v-toolbar-title>Picture</v-toolbar-title>
                    </v-toolbar>
                    <v-tabs vertical>
                      <!-- <v-tab>
                        ภาพหลัก
                      </v-tab> -->
                      <v-tab>
                        ภาพปก
                      </v-tab>

                      <!-- <v-tab-item>
                        <v-card>
                          <v-img
                            v-if="currentcontent.cover"
                            contain
                            :src="currentcontent.cover"
                            height="250"
                          ></v-img>
                          <v-card-title>
                            <v-file-input
                              v-model="covertmp"
                              label="650 x 240"
                              outlined
                              dense
                              @change="changecover"
                            ></v-file-input>
                          </v-card-title>
                        </v-card>
                      </v-tab-item> -->
                      <v-tab-item>
                        <v-card>
                          <v-img
                            v-if="currentcontent.thumbnail"
                            contain
                            :src="currentcontent.thumbnail"
                            height="250"
                          ></v-img>
                          <v-card-title>
                            <v-btn
                              color="error"
                              @click="removethumbnail"
                            >
                              ลบรูปภาพ
                            </v-btn>
                            <v-file-input
                              v-model="thumbnailtmp"
                              label="600 x 600 เป็น JPG หรือ PNG เท่านั้น"
                              outlined
                              dense
                              hide-details
                              @change="changethumbnail"
                            ></v-file-input>
                          </v-card-title>
                        </v-card>
                      </v-tab-item>
                    </v-tabs>
                  </v-card>
                </v-col>

                <v-col
                  cols="12"
                >
                  <v-switch
                    v-model="currentcontent.iscustom"
                    label="โหมดแก้ไขเอง"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <v-col
                  v-if="currentcontent.iscustom"
                  cols="12"
                >
                  <editor
                    v-model="currentcontent.content"
                    api-key="meyc5j7whq9ffae2lbneuptktk8r1nielyhx17lisx11exc2"
                    :init="{
                      plugins: ['wordcount','link', 'image','lists'],
                      file_picker_types: 'image',
                      file_picker_callback: updateimg,
                      height: 800,
                      toolbar: 'undo redo | styleselect | bold italic | link image | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent'
                    }"
                  />
                  <!-- undo redo | link image | numlist bullist -->

                  <!-- <tiptap-vuetify
                    v-model="currentcontent.content"
                    :extensions="extensions"
                    :toolbar-attributes="{ color: 'info' }"
                  /> -->
                </v-col>
                <v-col
                  v-else
                  cols="12"
                >
                  <v-card>
                    <v-toolbar
                      flat
                      color="primary"
                      dark
                    >
                      <v-toolbar-title>SEO Mode</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-menu
                        offset-y
                        top
                        left
                        open-on-hover
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            class="mx-2"
                            large
                            icon
                            color="indigo"
                            v-on="on"
                          >
                            <v-icon>
                              {{ icons.mdiPlusBox }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="addcomponent('Relate')">
                            <v-list-item-title>บทเชื่อม</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="addcomponent('Refine')">
                            <v-list-item-title>บทขยาย</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-toolbar>
                    <div class="px-2 pt-2 pb-6">
                      <v-card
                        v-for="(component, ci) in currentcontent.contentcomponents"
                        :key="ci"
                        style="margin-top: 1.25rem;"
                      >
                        <v-toolbar
                          dense
                          flat
                          color="primary"
                          dark
                        >
                          <v-toolbar-title class="mr-3">
                            {{ component.type }}
                          </v-toolbar-title>
                          <v-btn-toggle
                            v-model="component.titletype"
                            mandatory
                            rounded
                            dense
                          >
                            <v-btn
                              color="primary"
                              value="h1"
                            >
                              h1
                            </v-btn>
                            <v-btn
                              color="primary"
                              value="h2"
                            >
                              h2
                            </v-btn>
                            <v-btn
                              color="primary"
                              value="h3"
                            >
                              h3
                            </v-btn>
                            <v-btn
                              color="primary"
                              value="h4"
                            >
                              h4
                            </v-btn>
                            <v-btn
                              color="primary"
                              value="h5"
                            >
                              h5
                            </v-btn>
                          </v-btn-toggle>
                          <v-spacer></v-spacer>
                          <v-btn
                            large
                            icon
                            color="indigo"
                            @click="removecomponent(ci)"
                          >
                            <v-icon>
                              {{ icons.mdiMinusBox }}
                            </v-icon>
                          </v-btn>
                          <!-- <v-btn
                            large
                            icon
                            color="indigo"
                            @click="() => { component.p.push('') }"
                          >
                            <v-icon>
                              {{ icons.mdiPlusBox }}
                            </v-icon>
                          </v-btn> -->
                        </v-toolbar>
                        <v-row class="px-2 py-2">
                          <v-col>
                            <v-text-field
                              v-model="component.title"
                              label="Title* (ไม่ควรเกิน 60 ตัวอักษร)"
                              counter
                              dense
                              outlined
                              :rules="[v=> !!v || 'Required']"
                            ></v-text-field>
                          </v-col>
                          <v-col v-if="component.type === 'Relate'">
                            <v-autocomplete
                              v-model="component.content_id"
                              :items="contents"
                              :search-input.sync="findcontenttitle"
                              hide-no-data
                              hide-selected
                              dense
                              outlined
                              item-text="title"
                              item-value="_id"
                              label="Content ที่เชื่อม"
                              :rules="[v=> !!v || 'Required']"
                            ></v-autocomplete>
                          </v-col>
                          <v-col
                            v-for="(text, ctp) in component.p"
                            :key="ctp"
                            cols="12"
                          >
                            <v-textarea
                              v-model="component.p[ctp]"
                              label="Paragraph"
                              hide-details
                              dense
                              outlined
                            ></v-textarea>
                            <v-btn
                              class="mt-1 mr-2"
                              depressed
                              color="error"
                              @click="removeparagraph(component.p, ctp)"
                            >
                              ลบ Paragraph
                            </v-btn>
                            <v-btn
                              class="mt-1"
                              depressed
                              color="info"
                              @click="() => { component.p.push('') }"
                            >
                              เพิ่ม Paragraph
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="success"
              @click="savecontent"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog
      v-model="viewdemo"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">View</span>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="viewdemo = false"
          >
            X
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div :style="{ width: '100%', display: 'flex', justifyContent: 'center'}">
            <iframe
              :style="{width: '450px', height: '650px'}"
              :src="`https://${mainpage.domain}/${viewref}`"
            ></iframe>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
  mdiCamera,
  mdiMagnify, mdiPlusBox, mdiMinusBox,
} from '@mdi/js'

import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,

  // BulletList,
  // OrderedList,
  ListItem,
  Image,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from 'tiptap-vuetify'
import moment from 'moment'

import Gallery from '@/components/Gallery.vue'
import Editor from '@tinymce/tinymce-vue'

export default {
  components: { TiptapVuetify, editor: Editor },
  setup() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiCamera,
        mdiMagnify,
        mdiPlusBox,
        mdiMinusBox,
      },
    }
  },
  data() {
    return {
      covertmp: null,
      thumbnailtmp: null,
      search: '',
      dialog: false,
      viewdemo: false,
      imgdialog: false,
      viewref: '',
      findcontentgroupname: '',
      contentgroups: [],
      extensions: [
        History,
        Blockquote,
        Link,
        [Image,
          {
            options: {
              imageSources: [
                { component: Gallery, name: 'Gallery' },
              ],
            },
          }],
        Underline,
        Strike,
        Italic,
        ListItem,

        // BulletList,
        // OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      statusitems: [
        { label: 'กำลังทำ', value: 'draft' },
        { label: 'เสร็จ', value: 'finish' },
        { label: 'เผยแพร่', value: 'active' },
        { label: 'ต้องปรับแก้', value: 'reject' },
      ],
      headers: [
        { text: 'ชื่อ', align: 'start', value: 'title' },
        { text: 'เกริ่นนำ', value: 'description' },
        { text: 'ลิ้งค์', value: 'ref' },
        { text: 'หมวด', value: 'contentgroup.name' },
        { text: 'สถานะ', value: 'status' },
        { text: 'วันที่อัพเดท', value: 'updatedAt' },
        { text: 'Action', value: 'action', width: '100' },
      ],
      contents: [],
      content: '',
      findcontenttitle: '',
      mainpage: {

      },
      dialogmode: 'new',
      currentcontent: {
        content: '<p></p>',
        contentcomponents: [],
        status: 'draft',
      },
    }
  },
  watch: {
    async findcontentgroupname(val) {
      if (this.contentgroups.length > 0) return
      const { data: { contentgroups } } = await this.axios.get('/admin/contents/title', {
        params: { title: val },
      })
      this.contentgroups = contentgroups
    },
    async findcontenttitle(val) {
      if (this.contents.length > 0) return
      const { data: { contents } } = await this.axios.get('/admin/contents/title', {
        params: { title: val },
      })
      this.contents = contents
    },
  },
  async created() {
    const { data } = await this.axios.get('/admin/mainpage')
    this.mainpage = data.website
    this.getcontents()
    this.findcontentgroups()
  },
  methods: {
    async removethumbnail() {
      if (confirm('ยืนยันการลบรูปภาพ')) {
        await this.axios.delete('/admin/content/thumbnail', {
          params: { _id: this.currentcontent._id },
        })
        await this.getcontents()
        this.dialog = false
      }
    },
    viewcontent(item) {
      console.log(item)
      this.viewref = `${item.contentgroup.ref}/${item.ref}`
      this.viewdemo = true
    },
    async updateimg(cb, value, meta) {
      const input = window.document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')

      input.addEventListener('change', async e => {
        const file = e.target.files[0]

        const image = new FormData()
        image.append('img', file)
        image.append('alt', file.name)
        const { data } = await this.axios.post('/admin/image', image)

        cb(`https://laokda.deepcdn.net/${data.img.Key}`, { title: file.name })
      })

      input.click()
    },
    removeparagraph(arr, index) {
      arr.splice(index, 1)
    },
    removecomponent(index) {
      this.currentcontent.contentcomponents.splice(index, 1)
    },
    contentgroupidtotext(_id) {
      let g = {}
      if (this.contentgroups && this.contentgroups.length) {
        g = this.contentgroups.find(group => group._id === _id)
      }

      return g?.ref || ''
    },
    addcomponent(type) {
      if (type === 'Relate') {
        this.currentcontent.contentcomponents.push({
          type: 'Relate', content_id: '', title: '', p: [''],
        })
      } else {
        this.currentcontent.contentcomponents.push({
          type: 'Refine', title: '', p: [''],
        })
      }
      console.log(type)
    },
    async findcontentgroups() {
      const { data: { contentgroups } } = await this.axios.get('/admin/contentgroups/name')
      this.contentgroups = contentgroups
    },
    changecover(img) {
      const reader = new FileReader()
      reader.onload = e => {
        this.currentcontent.cover = e.target.result
        this.$forceUpdate()
      }
      reader.readAsDataURL(img)
    },
    changethumbnail(img) {
      const reader = new FileReader()
      reader.onload = e => {
        this.currentcontent.thumbnail = e.target.result
        this.$forceUpdate()
      }
      reader.readAsDataURL(img)
    },
    editcontent(item) {
      this.dialogmode = 'edit'
      this.currentcontent = item
      this.dialog = true
    },
    newcontent() {
      this.dialogmode = 'new'
      this.dialog = true
      this.currentcontent = {
        status: 'draft',
        contentcomponents: [],
      }
    },
    async getcontents() {
      let { data: { contents } } = await this.axios.get('/admin/contents')
      contents = contents.map(content => ({
        ...content,
        action: '',
        updatedAt: moment(content.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        createdAt: moment(content.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      }))
      this.contents = contents
    },
    getstatustext(statusvalue) {
      const status = this.statusitems.find(item => item.value === statusvalue)

      return status.label
    },
    async savecontent() {
      if (this.$refs.currentcontent.validate()) {
        try {
          // const currentcontent = {
          //   title: this.currentcontent.title,
          //   description: this.currentcontent.description,
          //   ref: this.currentcontent.ref,
          //   group: this.currentcontent.group,
          //   status: this.currentcontent.status,
          //   keywords: this.currentcontent.keywords,
          //   content: this.currentcontent.content,
          // }
          const contentform = new FormData()
          contentform.append('title', this.currentcontent.title)
          contentform.append('description', this.currentcontent.description)
          contentform.append('ref', this.currentcontent.ref)
          contentform.append('group', this.currentcontent.group)
          contentform.append('status', this.currentcontent.status)
          contentform.append('keywords', this.currentcontent.keywords)
          contentform.append('content', this.currentcontent.content)
          contentform.append('contentgroup_id', this.currentcontent.contentgroup_id)
          contentform.append('iscustom', this.currentcontent.iscustom)
          contentform.append('contentcomponents', JSON.stringify(this.currentcontent.contentcomponents))

          if (this.covertmp) {
            contentform.append('coverimg', this.covertmp)
          }
          if (this.thumbnailtmp) {
            contentform.append('thumbnailimg', this.thumbnailtmp)
          }
          if (this.dialogmode === 'edit') {
            await this.axios.put('/admin/content', contentform, { params: { _id: this.currentcontent._id } })
          } else {
            await this.axios.post('/admin/content', contentform)
          }
          await this.getcontents()
        } catch (error) {
          if (error.response.status === 401) {
            this.$cookies.set('t', '')
            this.$router.push({ name: 'Login' })
          }
        }
        this.dialog = false
      }
    },
    async deleteeditcontent(item) {
      await this.axios.delete('/admin/content', { params: { _id: item._id } })
      await this.getcontents()
    },
  },
}
</script>
